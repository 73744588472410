@tailwind base;
@tailwind components;
@tailwind utilities;

.text-truncate {
  width: 250px;
  /*overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; */
}

@media (max-width: 1024px) {
  .text-truncate {
    width: 200px;
  }
}

.navigation::-webkit-scrollbar {
  width: 0.7em;
  background-color: #f5f5f5;
}

/* Style the thumb (the part of the scrollbar that the user can drag) */
.navigation::-webkit-scrollbar-thumb {
  background-color: #ddd;
  border-radius: 10px;
}
